@charset "utf-8";


html {
  --green: #228c3d;
  background-color: #eeeeee !important;
}

html body {
  color: #333333;
  --bs-body-bg: #eeeeee;
  background-color: #eeeeee;
  font-family: "Assistant", sans-serif !important;
  /* padding-top: 46px; */
  /* direction: ltr; */
}

@media (min-width: 1400px) {
  body .container {
    max-width: 1195px;
  }
}

@media (max-width: 760px) {
  body .container {
    max-width: 100%;
    padding: 0;
  }
}

.starter_panel {
  position: sticky;
  /* top: 0px; */
  /* width: 100%; */
  /* height: 52px; */
  /* z-index: 2; */
  z-index: 1;
  top: 10%;
}

.container {
  /* margin-right: auto; */
  /* margin-left: auto; */
  /* padding-right: 16px; */
  /* padding-left: 16px; */

}

.rules_content {
  text-align: start;
}

.mimi {
  background-color: black;
  color: #333333;
}

.form_title {
  font-size: 26px;
  color: #003c37;
  padding: 5px 20px -12x;
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 760px) {
  .form_title {
    margin: 0;
  }
}

.form_subtitle {
  font-size: 20px;
  padding: 20px 0px 10px 0px;
}

.rules {
  font-family: system-ui;
  font-size: large;
}

.headerPart {

  padding: 20px 224px 4px 231px;
}

.headerPartMarshal {
  /* padding: 25px 291px 4px 279px; */
  /* margin-right: 50%; */
  margin-right: 30%;
}
@media (max-width: 760px) {
  .headerPartMarshal {
    padding: 15px;
    margin-right: 0%;
  }
}
.headerPartMarshal {
  /* padding: 25px 291px 4px 279px; */
  /* margin-right: 50%; */
  /* margin-right: 30%; */
}
@media (max-width: 560px) {
  .headerPart {
    padding: 15px;
  }
}
@media (max-width: 760px) {
  .headerPart {
    margin-right: 0%;
  }
}

.butcenter {
  max-width: 50%;
  margin: auto;


}

.but {
  padding-right: 2px;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.TopDashboard .btn-success {
  background-image: none;
  background-color: #003b36;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  padding: 8px;
  padding-left: 7%;
  padding-right: 7%;
  text-align: center;
  border-style: none;
  margin-bottom: 22px;
}

.TopDashboard>.btn-success>a:hover {
  background-image: none !important;
  background-color: transparent !important;
}

.TopDashboard {
  background-image: url(../images/dashboard-top-1170.jpg?axis_nonce=fd87d2e4637b35128fbe7996d6c85020c91180f80c3be3260b);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 12.7%;
  margin-bottom: 30px;
  /* width: 102%; */
  /* position: relative; */
}

@media (max-width: 760px) {
  .TopDashboard {
    max-width: 100%;
  }
}

.TopDashboard .btn:hover,
.TopDashboard .btn.hover {
  background-color: #002d29;
}

body .btn {
  background-image: none;
  background-color: #228c3d;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;

}

body .btn:hover,
body .btn.hover {
  background-color: #18672d;
  color: #fff;
}

body .btn-default {
  background-color: #fff;
  border-color: #a3c2b1;
  text-shadow: none;
  width: 33%;
}

body .btn-default-remove {
  background-color: #fff;
  border-color: #a3c2b1;
  text-shadow: none;
  /* width: 33%; */
}

body .btn-default:hover,
body .btn-default.hover {
  background-color: #c5dacb;
  border-color: #a3c2b1;
}

/* .thumbnail,
.img-thumbnail {
  border-color: #a3c2b1;
} */

/* .thumbnail {
  margin-bottom: 0px;

} */

.thumbnail-current {
  border: 1px solid #228c3d;
  background-color: #f5f3e6;
}

.day_box_title {
  color: #003c37;
  font-size: 18px;
}

.day_name {
  font-size: 13px;
}

/* a.thumbnail:hover {
  border-color: #228c3d !important;
  background-color: #dfece2;
} */

a:hover {
  border-color: #228c3d !important;
  text-decoration: none;
}

.thumbnaildisabled {
  display: block;
  padding: 4px;
  margin-bottom: 0px;
  line-height: 1.42857143;
  background-color: #e4e4e4;
  color: #adadad;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: none;
}

a:hover {
  border-color: #d4d4d4 !important;
  text-decoration: none !important;
}

.disabledtext {
  color: #adadad;
}

.container .jumbotron {
  border-radius: 0px;
  text-align: center;
}

.jumbotron-row {
  display: flex;
  justify-content: center;
  margin: 8px;
  padding: 16px;
  min-height: 300px;
}

.rss_title {
  color: #333333;
  font-size: 20px;
  font-weight: bold;
}

.rss_time {
  margin-top: 10px;
  color: #666666;
  font-size: 12px;
}

.rss_text {
  color: #28734d;
  font-size: 14px;
}

.main_player_wrap {
  border-right: 1px solid #d6e2dc;
  margin-bottom: 20px;
  text-align: right;
  padding-right: 40px;
}

.player_title {
  color: #333333;
  font-size: 16px;
}

.player_name {
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.player_text {
  color: #333333;
}

/* .PaddingBottom20 {
  padding-bottom: 20px;
  text-align: right;
  border-left: 1px solid #C4D9CE;
  border-right: none;
} */
.PaddingBottom20 {
  padding-bottom: 20px;
  text-align: right;
}

.RightToLeft .PaddingBottom20 {
  border-left: 1px solid #C4D9CE;
  border-right: none;
}

.LeftToRight .PaddingBottom20 {
  border-left: none;
  border-right: 1px solid #C4D9CE;
}

.footer {
  margin: 0 !important;
}

.FooterBottom {
  background-color: #333333;
  color: #fff;
  /* font-size: 12px;
  min-height: 38px;
  margin: auto;
  display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* margin-right: -13px; */
  /* margin-top: 0px; */
  /* width: 100%; */
}

.footer-bg img {
  width: 60%;
}

.FooterTextBottom {
  /* text-align: right; */
  /* max-width: 100%; */
  /* margin-right: -13px;
  width: 103% !important; */
  margin-top: 0.5%;

}

.PaddingLeft20 {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .BorderLeft {
    border-left: 0px;
    border-bottom: 1px solid #c4d9ce;
    padding-bottom: 20px;
  }
}

.FooterBg {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.navbar,
.navbar-fixed-top,
.navbar-inverse,
.navbar-custom,
.navbar-header {
  background-color: #228c3d;
  background-image: none;
  /* text-align: left; */
}

/* header {} */

.navbar-flex {
  display: flex;
  justify-content: space-between;
}

.navbar-inverse .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-toggle {
  float: right;
}

.container>.navbar-header {
  margin-right: 0px;
  margin-left: 0px;
}

.FooterTextBottom a {
  color: #fff;
}

.FooterTextBottom a:hover {
  color: #ccc;
}

.PaddingLeft5 {
  padding-left: 5px !important;
}

.DaysSection {
  /* margin: -30px; */
  /* flex-wrap: wrap; */
}

.day_box {
  padding: 7px;
  flex: 1 0 175px;
}

.day_box .thumbnail {
  width: 150px;
  /* max-width: 50px; */
  border: 1px solid #b1cabc;
  border-radius: 5px;
  background: #fff;
  padding: 5px 0;

}

/* .day_box:hover:not([disabled]), */
.day_box .current.thumbnail {
  border-color: #6db07f;
  background: #f5f3e6;

}

.day_box .thumbnail:disabled {
  border-color: #dedede;
  background: #e4e4e4;
}

@media (max-width: 760px) {
  .DaysSection {
    flex-wrap: wrap;
    padding: 0 4vw
  }

  .DaysSection .day_box {
    flex: unset;
    margin: 0;
    width: 33%;
  }

  .day_box .thumbnail {
    width: 30vw;
    margin: 0;
  }
}

.date_label {
  font-weight: bold;
}

.my_orders_panel_title {
  background-color: #dbe8e1;
  margin: 0px;
  height: 50px;
}

.my_orders_panel {
  background-color: #fff;
  border: 1px solid #c4d9ce;
  margin-top: 15px;
}

.my_orders_panel_title_date {
  font-size: 24px;
  color: #003c37;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 3px;
}

.my_orders_panel_title_box {
  color: #003c37;
  line-height: 110%;
  margin-top: 5px;
  margin-right: 10px;
  padding-left: 10px;
}

.my_orders_golfer_name {
  padding: 10px -3px 5px 1060px;
  color: #228c3d;
  font-weight: bold;
  font-size: 18px;
}

.my_orders_golfer_name.my_orders_name {

  margin-top: 5px;
  margin-right: 11px;
  padding-left: 14px;
}


.orders_golfer_name_box {
  clear: both;
  height: 45px;
  border-bottom: 1px solid #c4d9ce;
  margin-right: 10px;
  margin-left: 10px;
}

.orders_golfer_car_box {
  border-bottom: 1px solid #c4d9ce;
  margin-right: 10px;
  margin-left: 10px;
  min-height: 45px;
}

.orders_golfer_club_box {
  margin-right: 10px;
  margin-left: 10px;
  min-height: 45px;
  clear: both;
}

.add-car {
  background-image: url(../images/add-car-eng.png?axis_nonce=e8888475873c7d6025a02309f8fb89fbe5289538cf7e600e75);
  width: 30px;
  height: 30px;
  margin: 5px 5px 15px 5px;
}

.add-golf {
  background-image: url(../images/add-golf-eng.png?axis_nonce=664bd7bb756362b7c6437432c754f068f5621fd9d886595296);
  width: 30px;
  height: 25px;
  margin: 5px 5px 15px 5px;
  background-position-x: left;
  background-repeat: no-repeat;
}

.add-friend {
  background-image: url(../images/add-friend-eng.png?axis_nonce=8c7a166fc462d63f665d9067eecfe418e59dc537cd2493b371);
  width: 30px;
  height: 25px;
  margin: 5px 5px 15px 5px;
  background-position-x: left;
  background-repeat: no-repeat;
}

.CarAdded {
  padding-bottom: 10px;
}

.order_panel .form_subtitle {
  padding: 2px 0px 4px 0px !important;
}

.rulesSection {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-family: system-ui;
  font-size: large;
  font-weight: bold;

}

.rulesIndex {
  font-weight: bold;

}

.OrderCar {
  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.OrderGolf {
  padding-top: 10px;
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
}

.OrderCarLink {
  text-decoration: none !important;
  color: #333;
  display: flex;
}

div#remove_golfer_btn .btn-sm {
  /* padding: 2px 10px !important; */
  /* margin: 10px 1067px -149px 1060px !important; */
  /* margin: 10px 60% -15% 59% !important; */

  /* margin: 10px 1060px -149px 1060px !important; */
  /* margin-top: -100px !important; */
  display: flex;
  justify-content: flex-end;
}

div#remove_golfer_btn .btn-sm-btn-sm {

  display: flex;
  justify-content: flex-end;
  /* padding: 2px 10px !important; */
  /* margin: 10px 1067px -149px !important; */
  /* margin: 10px 60% -15% 59% !important; */

  /* margin-top: -100px !important; */
}


/* div#remove_golfer_btn .btn-sm {
  padding: 0.25% 1.6% !important;
  margin: 2% 85.5% -12% 85.5% !important;
  margin-top: -8% !important;
}

div#remove_golfer_btn .btn-sm-btn-sm {
  padding: 0.25% 1.6% !important;
  margin: 2% 85.7% -12% !important;
  margin-top: -8% !important;
} */

.orders_golfer_club_type {
  margin-bottom: 5px;
  max-width: 743px;
  clear: right;
}

@media (max-width: 760px) {
  .orders_golfer_club_type {
    padding-bottom: 1.5vw;
  }

  .orders_golfer_club_type>div {
    width: auto;
  }
}

@media (max-width: 540px) {
  .orders_golfer_club_type>div>div {
    display: flex;
    flex-direction: column-reverse;
    margin-inline-end: 5vw;
  }
}

@media (max-width: 400px) {
  .orders_golfer_club_type>div {
    padding: 0 2vw;
  }

  .orders_golfer_club_type>div>div {
    margin-inline-end: 0vw;
  }
  .day_box_title {
    font-size: 14px;
  }
}

.my_orders_golfer_clubs {
  padding: 0px 10px 0px 14px;
}

.my_orders_club_car {
  padding: 0px 10px 0px 14px;
}

.my_orders_footer {
  padding: 10px;
}

.my_orders_footer {
  height: 56px;
}

.Ident {
  padding-right: 3px;
  margin-top: -5px;
  vertical-align: top;
  background-image: url(../images/ident-left.png?axis_nonce=7da5fa4d5801239393dd856c5b950e3d26ccc926f1b970d361);
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  float: left;
  margin-left: 13px;
}

.TextIdent {
  padding-right: 5px;
  padding-top: 3px;
}

.my_orders_golfer_box {
  /* border-bottom: 1px solid #c4d9ce; */
  padding-bottom: 5px;
}

.orders_golfer_box {
  background-color: #fff;
  border: 1px solid #c4d9ce;
  margin-bottom: 4px;
}

.Name {
  padding-right: 3px;
  padding-left: 0%;

}

.navbar-inverse .navbar-nav>li>a {
  color: #fff;
  padding: 16px;
}

.navbar-inverse .navbar-nav>li>a:hover {
  color: #b4d8bd;
}

.navbar-inverse .navbar-toggle {
  border: none;
}

.navbar-brand {
  padding: 0px;
  text-align: start;
}

.clearboth {
  clear: both;
}

.starter_panel {
  background-color: #fff;
  border: 1px solid #C4D9CE;
  margin-top: 15px;
  height: auto;
  width: 300px;
}

.starter_panel_title {
  background-color: #DBE8E1;
  margin: 0px;
  padding: 5px 10px 1px 10px;
}

.PartnersTitle {
  border-bottom: 1px solid #C4D9CE;
  height: 45px;
  padding-top: 10px;
  background: #eeeeee;
}

.my_orders_footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}

.NoPadding {
  display: flex;
}

.time {}

.space {
  padding-bottom: 50px;
}

.navbar-inverse {
  border-color: #228c3d;
}

.navbar-inverse .navbar-collapse {
  border-color: #228c3d;
  /* margin-right: 250px; */
}

.select_lang {
  color: #228c3d;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

.select_lang a {
  color: #228c3d;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

.select_lang a:hover {
  color: #1b7030;
}

.login_title {
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  /* text-align: right; */
}

.login_box {
  text-align: center;
}

.user_name {
  margin-top: 10px;
  margin-bottom: 10px;
}

.user_name input {
  width: 100%;

}

.textField {
  font-size: 45px;
}

.textFieldL {
  font-size: 16px;
}

.user_password {
  margin-bottom: 10px;
  font-size: 16px;
}

.user_password input {
  width: 100%;
}

.forgot_pass {
  color: #228c3d !important;
  font-size: 16px;
  /* text-align: right; */
}

.forgot_pass:hover {
  color: #1b7030 !important;
  cursor: pointer;
}

.site_rules_box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.rules_text {
  font-size: 16px;
  text-align: right;
}

.rules_text a {
  color: #428bca;
}

.rules_text a:hover {
  color: #1b7030;
  text-decoration: underline !important;
}

.form-group {
  margin: 0px !important;
  padding: 0px !important;
}

.form-fields {
  padding: 0px !important;
}

.enterBtn {
  text-align: center !important;
}

body .btn-enter {
  background-color: #003b36;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 100px;
  margin-top: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

body .btn-info {
  border: 0px !important;
  width: 100%;
  color: #fff;
}

body .btn-sm {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

body .btn-reg {
  font-weight: bold;
  font-size: 18px;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 200px;
  margin: 0 auto;
}

.newcustomer_lbl {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  /* text-align: right; */
}

.NoMargin {
  margin: 0px !important;
}

.bg-image {
  background-image: url(../images/bg-login.jpg?axis_nonce=7b39d7597b4a94ad9d28a3d76dff6e0ece00243a7cead3b0fa);
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: bottom;
}

.selected_date {
  font-size: 16px;
  font-weight: bold;
  color: #003c37;
  text-align: center;
  margin-top: -1.5%;

}

.Padding-dir-5 {
  padding-left: 5px;
}

.Padding-opp-dir-5 {
  padding-right: 5px;
}

.Padding-dir-7 {
  padding-left: 7px;
}

.Padding-opp-dir-7 {
  padding-right: 7px;
}

.Padding-opp-dir-9 {
  padding-right: 9px;
}

.Padding-dir-9 {
  padding-left: 9px;
}



.registration .form-horizontal .form-group {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  display: flex;
}

.registration .form-horizontal .form-group div {
  flex-grow: 1;
}

.button_row {
  max-width: 100%;
  flex: 0 1;
  margin-right: 10px;
  margin-bottom: 10px;
}

.thumbnail:hover {
  border-color: #6db07f;
  background-color: #e4ede9;
  /* background: #f5f3e6; */
}

.registration .form-horizontal input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: 6px 12px;
  font-size: 14px;
}

.registration .form-horizontal label {
  width: 30%;
}

.registration .form-horizontal .gender-radio input[type="radio"] {
  float: right;
  width: auto;
  margin: 5px;
}

.registration .form-horizontal .gender-radio label {
  float: right;
  width: auto;
}

.form-control {
  margin-top: -5px;
  padding: 2px 6px;
}

.form-address {
  padding: 10px 0px 0px 0px;
  font-size: 20px;
  text-align: right;
}

div#SUBMIT .btn {
  width: 120px;
  font-weight: bold;
  font-size: 16px;
}

.Side {
  min-height: 10px;
}

@media (max-width: 500px) {
  .selected_date {
    font-size: 14px;
  }
}

.NoPadding {
  padding: 0px;
}

#choose-hours {
  /* margin: 0 86px; */
  margin: 0 8.96%;
}

#choose-hours button {
  flex: 1;
  margin: 0 5px;
  color: #000;
}


#choose-marshal {
  flex: 1;
  margin: 0 5px;
  color: #000;
}

.form_subtitlell {
  font-size: 20px;
  padding: 10px 15px 10px 6px;
}

.form_subtitlell_Marshal {
  /* font-size: 20px; */
  /* padding: 10px 97px 10px 92px; */
}

.selectMarshal {
  width: 70%;
  max-width: 80%;
}

.select {
  width: 55%;
}

@media (max-width: 760px) {
  .select {
    width: 100%;
  }
}

.hour {
  /* font-size: 22px; */
}

.Text14Green {
  font-size: 14px;
  color: #003c37;
}

.disabled-button {
  opacity: 0.5;
}

.hour_list_panel {
  background: #fff;
  border: 1px solid #c4d9ce;
}

.hour_value {
  /* font-size: 10px; */
  padding: 7px;
  text-align: center;
}

.hour_detail_box {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  min-height: 50px;
}
@media (max-width: 768px) {
  .hour_detail_box {
    font-size: 1vw;
  }
}
.hour_detail_box_disabled {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  height: 74px;
  text-align: center;
  padding-top: 25px;
}

.hour_detail_green {
  background-color: #e4ede9;
}

.hour_list_panel .row {
  margin: 0px;
}

.ClosedYard {
  background-color: #e6e6e6;
  color: #999999;
  border-top: 1px solid green;
  /* border-bottom: 1px solid green; */
}

.Text13 {
  font-size: 13px;
}

.disabled {
  line-height: 90%;
}

.disabled-item {
  pointer-events: none;
  opacity: 0.5;
}

.GolfCar {
  width: 18px;
  min-height: 10px;
  /* float: left; */
}

.order_box {
  text-align: center;
  padding-top: 10px;
}

.order_box .btn {
  padding: 3px 7px !important;
}

.input-numner {
  border: 1px solid #c4d9ce !important;
  height: 30px !important;
}

.magnify {
  margin-top: -2px;
  cursor: pointer;
}

.golfer_mate_name {
  font-weight: bold;
  margin-bottom: 20px;
}

.modal-header {
  background-color: #228c3d;
  padding: 8px 15px !important;
  color: #ffffff;
  border-radius: 5px 5px 0px 0px;
  border-bottom: none;
}

.modal-header .close {
  color: #fff;
  text-shadow: none;
  opacity: 0.7;
  margin-top: 1px;
  float: right;
}

.modal-header .close:hover {
  opacity: 1;
  color: #fff;
}

.modal-style {
  display: unset !important;
  background-color: unset !important;
}
@media (max-width: 297px) {
  .hour_detail {
    font-size: 3vw;
  }
  .day_box_title {
    font-size: 12px;
  }
}
@media (max-width: 200px) {
  .hour_detail {
    font-size: 3vw;
  }
  .day_box_title {
    font-size: 8px;
  }
}
@media (max-width: 1000px) {
  .hour_detail_box {
    min-height: 90px;
    font-size: 2vw;
  }

  .hour_value {
    padding-top: 30px;
    /* font-size: 4vw; */

  }

  .ClosedYard .hour_value {
    padding-top: 7px !important;
  }

  .order_box {
    padding-top: 30px;
    padding-right: 2%;

  }

  .form-horizontal {
    padding: 70px 657px 159px 151px;
  }

  .form-address {
    padding: 10px 0px 0px 0px;
  }
}

div#SUBMIT .btn {
  margin-right: 15px;
}

.pull-opp-dir {
  float: revert;
}

.text-dir {
  text-align: left;
}

.flag_icon {
  float: right;
}

.flag_box {
  float: left;
  padding-right: 20px;
  margin-top: 50px;
}

.select_lang {
  float: right;
}

.Padding-dir-20 {
  padding-right: 20px;
}

.Border-dir {
  border-right: 1px solid #c4d9ce;
}

.navbar-toggle {
  margin-right: 0px !important;
}

.registration {
  padding-bottom: 30px;
  width: 60%;
  margin: auto;
}

/* * {
  direction: ltr !important;
  text-align: left !important;
} */

.DateCenter {
  margin: 0 auto;
  width: 250px;
  /* padding-top: 10px; */
  display: flex;
  flex-wrap: nowrap;

}


.DateCenter-left {
  align-content: stretch;
  align-items: center;
  flex-direction: row-reverse;

}

.DateCenter-right {
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
}

.Devider {
  border-bottom: 1px solid #c4d9ce;
  height: 2px;
}

.orders_golfer_club_type .btn {
  background-color: #fff;
  color: #999;
}

.orders_golfer_club_type .btn-primary {
  border-color: #c4d9ce;
  background-color: #fff;
  float: left;
  /* width: 78px; */
}

.orders_golfer_club_type .active {
  background-color: #cfe0d8;
  color: #000;
  box-shadow: none;
  width: 81px;
}

.ord_approval {
  text-align: center;
  /* margin-top: 3%; */

}

.add_golfer_box {
  margin-top: -15px;
}

@media (min-width: 768px) {
  .navbar-nav {
    float: right;
    margin-top: 12px;
  }

}

.nav-style {
  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap; */
  /* width: 100px; */
  /* margin-right: 50%; */
  /* margin-left: 50%; */
  /* margin-top: 1%; */
  /* float: right !important; */


}

.nav-style-RightToLeft {

  /* margin-left: 50%; */
  /* margin-right: 58px !important; */
  /* float: right !important; */
  float: left !important;

}

.nav-style-LeftToRight {
  /* margin-right: -50%; */
  /* margin-right: -42px !important; */
  /* margin-right: -400px; */
  float: right !important;

}

.maincontent {
  margin-top: 50px;
}

.hour_detail:nth-child(2n) {
  background-color: #e4ede9;
}

.backward_box .btn-sm,
.forward_box .btn-sm {
  font-size: 15px;
  font-weight: bold;
  color: #228c3d !important;
}

.starter_panel {
  background-color: #fff;
  border: 1px solid #c4d9ce;
  margin-top: 15px;
  height: auto;
  width: 300px;
}

@media screen and (min-width: 400px) {
  .starter_panel {
    min-width: 360px;
  }
}

.starter_panel_title {
  background-color: #dbe8e1;
  margin: 0px;
  padding: 5px 10px 1px 10px;
}

.starter_panel_title_hour {
  font-size: 24px;
  color: #003c37;
}

.hour_picker {
  display: inline-block;
}

.box_center {
  text-align: center;
}

.starter_panel_title_line_1 {
  line-height: 120%;
}

.PaddingTop3 {
  padding-top: 3px;
  display: flex;
}

.PartnersTitle {
  border-bottom: 1px solid #c4d9ce;
  height: 45px;
  padding-top: 10px;
  background: #eeeeee;
}

.all {
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: space-evenly;
  align-items: baseline;
}

.PartnerRow {
  border-bottom: 1px solid #c4d9ce;
  min-height: 45px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
}

.Notes {
  color: #ff0000;
}

.starter_orders_golfer_name {
  padding: 0px 10px 5px 10px;
  color: #228c3d;
  font-weight: bold;
  font-size: 18px;
}

.has-error label {
  color: #a94442;
  text-align: right;
}


body .btn-reg-side {
  /* font-weight: bold;
  font-size: 18px; */
  padding-top: 7px;
  padding-bottom: 7px;
  margin-right: 7px;
  --bs-btn-color: #f8f9fa !important;

}

.plus-icon {
  background-image: url(../images/plus-icon.png?axis_nonce=3db4fc12d5e9c3bd0f1f75ef6c590c19cf5fd2d81f8fd9306d);
  background-repeat: no-repeat;
  width: 20px;
  background-position: right;
  height: 14px;
  float: right;
  margin-top: 5px;
}

.refresh-icon {
  background-image: url(../images/refresh-icon.png?axis_nonce=6816a2fb069b3b2f49902f19a254c44d0325a465c00132fa67);
  background-repeat: no-repeat;
  width: 20px;
  background-position: right;
  height: 17px;
  float: right;
  margin-top: 4px;
}

.buttons-entrance-control {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  /* max: 5px; */
}

.buttons-entrance-control button {
  width: fit-content;
}

.buttons-entrance-control .plus-icon {
  float: none;
  display: inline-block;
  margin: 0;
  background-position: center left;
}

body .control-head {
  border-bottom: 1px #c4d9ce solid;
  font-size: 20px;
  margin: 0;
}

.control-head>div {
  background: #dbe8e1;
  padding-top: 5px;
  padding-bottom: 5px;
}

.guides-control-table {
  background: #DBE8E1;
  border: 1px #c4d9ce solid;
  margin-top: 10px;
  min-height: 100px;
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
}

@media (max-width: 760px) {
  .guides-control-table>.control-head>div {
    width: 16.66666%;
    text-align: center;
    font-size: 3vw;
  }

  .guides-control-table .control-line {
    font-size: 3.2vw;
  }
}

@media (max-width: 760px) {
  .entrance-control-table>.control-head>div {
    width: 10.00000%;
    text-align: center;
    font-size: 2vw;
  }

  .entrance-control-table .control-line {
    font-size: 1.2vw;
  }
}

@media (max-width: 377px) {
  .forward_box {
/* flex-direction: row-reverse; */
transform: rotate(180deg); /* Rotate the buttons */

  }
  .button_container_forward_box
  {
    transform: rotate(180deg); /* Rotate the buttons */

  }

}

@media (max-width: 760px) {
  .my_orders_panel_title_box>.my_orders_panel_title_line_2>span {
    /* width: 10.00000%; */
    /* text-align: center; */
    font-size: 3.3vw;
    margin-right: 0px;
    padding-left: 0px;
  }

  /* .entrance-control-table .control-line {
    font-size: 1.2vw;
  } */
}
@media (max-width: 289px) {
  .my_orders_panel_title_box {
    /* width: 10.00000%; */
    /* text-align: center; */
    font-size: 3vw;

    margin-right: 0px;
    padding-left: 0px;
  }

  /* .entrance-control-table .control-line {
    font-size: 1.2vw;
  } */
}
@media (max-width: 760px) {
  .css-1rlh5cr-MuiTypography-root {
    /* font-size: 2vw; */
  }
}

@media (max-width: 540px) {
  .css-1rlh5cr-MuiTypography-root {
    /* font-size: 2vw; */

  }
}

@media (max-width: 300px) {
  .header-username-responsive {
    font-size: 3vw;
    /* margin-bottom: 60px; */


  }
}
@media (max-width: 220px) {
  .header-username-responsive {
    font-size: 1.5vw;
    /* margin-bottom: 60px; */


  }
}
.entrance-control-table {
  background: #fff;
  border: 1px #c4d9ce solid;
  margin-top: 10px;
  min-height: 100px;
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
}

.guidesDiary-control-table {
  background: #dbe8e1;
  border: 1px #c4d9ce solid;
  margin-top: 10px;
  min-height: 100px;
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
}


body .control-line {
  font-size: 18px;
  padding: 15px 0;
  margin: 0;
  border-bottom: 1px #c4d9ce solid;
}

.name-control {
  color: #228c3d;
  font-weight: 600;
}

.camera-icon {
  background-image: url(../images/camera-icon.png?axis_nonce=db9243cb7f1db36ffd0a9094ac30f0ff3617ef13f206c98dbc);
  background-repeat: no-repeat;
  width: 19px;
  height: 17px;
  /* position: absolute; */
  margin-right: 10px;
  margin-top: 3px;
  cursor: pointer; 

}
.camera-icon:hover {
  opacity: 0.8; 
}

.remark-icon {
  background-image: url(../images/remark-icone.png?axis_nonce=c7c17f77607aac389e55ef41c178def0bc4faeb7c0959ef1c8);
  background-repeat: no-repeat;
  width: 19px;
  height: 17px;
  position: absolute;
  margin-right: 10px;
  margin-top: 3px;
}

.approved-icon {
  background-image: url(../images/approved-icon.png?axis_nonce=eadf7bdc9412ca2f9b3241f7e1f686361dc639918c1c787d98);
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  height: 17px;
  float: right;
  margin-top: 4px;
}

.forbidden-icon {
  background-image: url(../images/forbidden-icon.png?axis_nonce=1af6dbc3de10189f7fb43dc2d43b797a2de2f014da3bf26a89);
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  height: 18px;
  float: right;
  margin-top: 4px;
}

.text-center {
  text-align: center;
}

.thumb-member {
  margin-top: -10px;
  margin-bottom: -10px;
}

.entrance-approved {
  color: #218c3d;
  font-size: 26px;
}

.entrance-not-approved {
  color: #ff0000;
  font-size: 26px;
}

@media (min-width: 768px) {
  #member-img .modal-dialog {
    width: min-content;
    margin: 30px auto;
  }

  #member-entrance-img .modal-dialog {
    width: min-content;
    margin: 30px auto;
  }
}


.month-selection {
  border-right: 1px #c4d9ce solid;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 200%;
  border-bottom: 0;
}

.month-select {
  text-align: center;
  border-top: 1px #c4d9ce solid !important;
  border-left: 1px #c4d9ce solid !important;
  border-bottom: 1px #c4d9ce solid;
  /* width: 20%; */
}



.month-select {
  color: #000;
  border-bottom: 1px #c4d9ce solid;
}

.month-select:hover,
.month-selected {
  background: #dfece2;
}

.nopadding {
  padding: 0;
}

.month-name {
  color: #228C3D;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 28px;
  font-size: 18px;

}

.labelMessageError {
  white-space: pre-line;
  color: #d71515;
}


.navbar {
  position: absolute;
  width: 100%;
  height: 46px;
  left: 0px;
  top: 0px;
}

.loginicon {
  position: absolute;
  width: 80px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  color: black;
  height: 40px;
  left: 85%;
  top: 10px;
  background: #2bc6ff;
  border-radius: 24px;
  cursor: pointer;
}

.loginicon:hover {
  color: white;
}

.login_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 30vh;
}

.form-box {
  margin-top: -56vh;
  opacity: 80%;
  background: green;
  padding-right: 5px;
  padding-left: 8px;
  border-radius: 49px;
  box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.2);
}

.login-text {
  width: 100%;
  color: white;
  border-bottom: 2px solid white;
  line-height: 30px;
  width: 100%;
  font-weight: bolder;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
}

label {
  color: black;
  text-align: center;
}


.has-error {
  color: red;
}

.forget-password label,
.change-password label {
  text-align: start;
  display: block;
  color: black;
  padding-top: 10px;
}

.change-password p {
  color: black;
}

.forget-password input,
.change-password input {
  height: 1.5rem;
}

.link-to-feedback {
  cursor: pointer;
  color: white;
  padding: 5px 8px;
}


.link-to-feedback:hover {
  color: #c5dacb;
}

.header-style {
  width: 100% !important;
  max-width: unset !important;
  margin: unset !important;
  padding: unset !important;
}

.navbar-header {
  display: flex;
  width: 100%;
  /* padding: 0 135px; */
}

.navbar-header a {
  display: flex;
  align-items: center;
}

.navbar-inverse .navbar-nav>li>a {
  color: #fff;
  padding: 5px 8px;
}

.login-box {
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 2px;
  padding-left: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 5px;
  font-size: 11pt;
  background: transparent;
  transition: 1s;
  outline: none;
}

.login-btn {
  width: 75%;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  margin-top: 5px;
  border-radius: 20px;
  border: none;
  font-size: 15pt;
  font-weight: 700;
  transition: 1s;
  outline: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.login-btn:hover {
  transform: scale(1.03);
  letter-spacing: 4px;
  background-color: #7fffd4;
  color: #1e90ff;
}

.show {
  display: block;
  transition: 800ms;
  opacity: 1;
}

.show.active {
  transform: translateY(100px) scale(0) rotateY(180deg);
  opacity: 0;
}

.pick_day_box button {
  border: 0;
  background: none;
}

.img-responsive {
  float: left;
  /* margin-top: -100px; */
  width: 68px;

}

.img-responsive-entranceControl {
  float: left;
  margin-top: -12px;
  width: 42px;

}

.img-responsive-guidesDiary {
  margin-top: -11px;
  width: 42px;

}

.main {
  height: 100%;
}

.select-friend {
  color: #228C3D;
  background: white;
  color: #228C3D;
  background: white;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  font-size: 1rem;
  padding: 4px 8px;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #228C3D;
  color: white;
  background: #228C3D;
}

.EntranceControl {
  max-width: 1919px !important
}

@media (min-width: 1400px) {
  body .EntranceControl {
    max-width: 1170px !important;
  }
}

.row-GuidesDiary {
  margin-right: 0 !important;
  margin-left: 0 !important;

}

.course_calendar {
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-bottom: 1px #C4D9CE solid !important;
  padding-bottom: 15px !important;
}

.remark {
  border: floralwhite;
}

.editButton {
  float: left !important;
  background-image: none;
  background-color: #003b36;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-style: none;
  margin-bottom: 22px;

}

.rectanglered {
  background-color: red;

}

.rectangleblue {
  background-color: blue;

}

.rectanglegreen {
  background-color: green;

}

.rectangle {
  border-radius: 25px;
  padding: 20px;
  width: 249px;
  height: 150px;
}

.Inner-rectangle {
  border-radius: 10px;
  padding: 15px;
  width: 40px;
  height: 3px;
  background-color: #FF1493
}

.title {
  color: white;
  font-size: small;
  margin: auto;
}

.table {
  width: 100%;
  border: none;
}

.button-Rules {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;

}
@media (max-width: 300px) {
  .order_box .btn {
    padding: 3px 1px !important;

  }
}
@media (max-width: 760px) {
  .row {
    /* max-width: 100%; */
    margin: 0 auto;
  }

  .select_area .btn-group-justified {
    display: flex;
    justify-content: space-between;
  }

  .select_area .btn-group-justified button {
    width: 30%;
    padding: 0.8vw
  }
}
/* @media (max-width: 760px) {
  .starter_panel {
    position: absolute;
    top: -100vh;
  }

 
} */
.css-1qbvm60{
       padding-bottom: 0px !important; 
     margin-top: 0px !important;
}