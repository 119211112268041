.header-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* margin-top: -40px; */

}

.header-links a {
    color: white;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}

.header-menu-toggle {
    display: none;
}

@media screen and (max-width: 850px) {
    .header-menu-toggle {
        display: block;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 24px;
        cursor: pointer;
        padding: 12px;
        margin-right: 10px;
    }

    .header-links {
        display: none;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        background-color: #228c3d;
        z-index: 1;
    }

    .header-links--show-menu {
        display: flex;
    }

    .header-links a {
        font-size: 16px;
        line-height: 20px;
        padding: 10px;
        border-radius: 0;
    }

    .header-menue {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-end;
    }

    .header-menue a {
        color: white;

    }

}