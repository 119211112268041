/* עיצוב כללי לכל הריבוע של הפופאפ */
.modal {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  z-index: 100;
  position: fixed;
  height: unset;
  top: 5vh;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  display: unset !important;
}

/* עיצוב לכותרת של הפופאפ - הירוקה */
.modal__header {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 1rem 0.5rem;
  background: #228C3D;
  color: white;
}

.modal__header h2 {
  display: inline-block;
  font-size: 1.2em;
}

.x_close {
  float: left;
  font-size: 15px;
  padding-left: 6px;
  font-weight: bold;
  color: rgb(242, 240, 240);
  cursor: pointer;
}

.x_close_LeftToRight {
  float: left;

}

.x_close_RightToLeft {
  float: right;

}

.x_close:hover {
  color: rgb(213, 207, 207);
}

/* עיצוב לטקטסט שבתוך הפופאפ */
.modal__content {
  color: rgb(27, 69, 147);
  font-size: 1rem;
  padding: 1rem 0.5rem;
}

/* עיצוב לפוטר של הפופ אפ - כפתורים בד"כ */
.modal__footer {
  padding: 0.7rem 0.5rem;
  text-align: left;
  margin-top: -23px;
  padding-bottom: 7%;

}

/* הנפשות----- */
.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}


/* עיצובים לכפתורי הסגירה של הפופאפ  ----------------------------------------------------------*/
.modal_button {
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', 'sans-serif';
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */

  width: 5em;
  font-size: 1rem;
  text-align: center;
  padding: 4px 8px;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #228C3D;

  color: white;
  background: #228C3D;
  margin-block: 5px;

}

.modal_button:focus {
  outline: none;
}

.button_left:hover,
.button_left:active {
  background: #228c3ddb;

  border-color: #228c3ddb;

}

.button_right {
  float: right;
  color: #228C3D;
  background: white;
}

.button_right:hover,
.button_right:active {
  background: #228C3D;

  color: white;
}


/*קלאסים לכל מיני שימושים שונים במודל*/
.textarea-container {
  text-align: center;
}

@media (max-width: 760px) {
  .modal {
    width: 80% !important;
    left: 10% !important;
    right: 10% !important
  }
}