body {
	/* direction: ltr !important; */
	text-align: right !important;
	font-family: 'Assistant';
}

/* 
* {
	direction: ltr !important;
	text-align: left !important;
} */

.pull-opp-dir {
	float: revert;
}

.pull-dir {
	float: right !important;
}

.pull-dir-right {
	float: right !important;
}

.pull-dir-left {
	float: left !important;
}

.GolfCar {
	/* float: right; */
}

.flag_icon {
	float: left;
}

.flag_box {
	float: left;
}

.select_lang {
	float: left;
}

.Padding-dir-5 {
	padding-right: 5px;
}

.Padding-opp-dir-5 {
	padding-left: 5px;
}

.Padding-dir-7 {
	padding-right: 7px;
}

.Padding-opp-dir-7 {
	padding-left: 7px;
}

.Padding-dir-9 {
	padding-right: 9px;
}

.Padding-opp-dir-9 {
	padding-left: 9px;
}

.modal-header .close {
	float: left;
}

.Padding-dir-20 {
	padding-left: 20px;
}

.Border-dir {
	border-left: 1px solid #C4D9CE;
	border-right: none;
}

.navbar-toggle {
	float: left !important;
}

.login_title {
	/* text-align: right; */
	/* margin-right:15px; */
}

.navbar,
.navbar-fixed-top,
.navbar-inverse,
.navbar-custom,
.navbar-header {
	text-align: right;
	width: 200%;

}

a {
	text-decoration: none;
}

.Ident {

	float: right;
	background-image: url(../images/ident.png?axis_nonce=7d7450f5edccf07bc190234c2f47fefc54222b3378233e09b6);

	margin-left: 0px;
}

.my_orders_panel_title_box {
	border-right: 1px solid #C4D9CE;
	border-left: none;
	padding-right: 10px;
	margin-right: 3px;
}

div#SUBMIT .btn {
	margin-left: 15px;
}

.add-car {
	/* float: right; */
	background-image: url(../images/add-car.png?axis_nonce=848a820aa2cb20a46af29050c896a05ed7ffcad80f13e92e3c);
}

.add-golf {
	float: right;
	background-image: url(../images/add-golf.png?axis_nonce=73e6dbbe3b22f272a237825665cbf5965d55e5109be5c2deaa);
}

.add-friend {
	/* float: right; */
	background-image: url(../images/add-friend.png?axis_nonce=8906b7b91d39cb928c70b556d351e05bc54402faefc4810525);
}

.orders_golfer_club_type .btn-primary {
	float: right;
}

.orders_golfer_club_type .active {
	float: right;
}

.orders_golfer_club_type {
	max-width: 1000px;
	clear: left;
}

@media (min-width: 768px) {
	.navbar-nav {
		/* float: left !important; */
	}

	/* .navbar-header {
    float: right !important;
}   */

	.navbar-nav>li {
		float: right;
	}
}

.navbar-brand {
	float: right;
	width: 30%;
}


.modal {
	font-size: 12px;
}

.modal>.header {
	width: 100%;
	border-bottom: 1px solid gray;
	font-size: 18px;
	text-align: center;
	padding: 5px;
}


.modal>.content {
	width: 100%;
	padding: 10px 5px;
}

.modal>.actions {
	width: 100%;
	padding: 10px 5px;
	margin: auto;
	text-align: center;
}

.modal>.close {
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: -10px;
	top: -10px;
	font-size: 24px;
	background: #ffffff;
	border-radius: 18px;
	border: 1px solid #cfcece;
}

.MainPaperStayle {
	background: none;
	Border: none;
}

.fake-link {
	color: #428bca;
	/* text-decoration: underline; */
	cursor: pointer;
}

.fake-link:hover {
	color: #428bca;
	/* text-decoration: underline; */
	font-weight: bold;
}

.fake-link-add-car {
	color: #428bca;

}

.order-car-link-add-friend {
	color: black;
	display: flex;
	align-content: space-around;
	/* justify-content: flex-start; */
	align-items: center;
	font-weight: bold;
}

.order-car-link-add-friend:hover {
	color: #428bca;

}

.order-car-link {

	color: #428bca;
	display: flex;
	align-content: space-around;
	/* justify-content: flex-start; */
	align-items: center;
	font-weight: bold;

}

.order-car-link:hover {
	color: black;

}


.Register-submit {
	width: 162px !important;
	font-weight: bold !important;
	font-size: 16px !important;
	margin-left: 50% !important;
	margin-right: 50% !important;
}

.navbar-header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 1;
}

.navbar-nav {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-grow: 1;
}

.navbar-nav li {
	margin-right: 10px;
	/* add some margin between the navigation items */
}

.navbar-nav li:last-child {
	margin-right: 0;
	/* remove margin from the last navigation item */
}

.navbarMimi {
	width: 100%;
	background-color: #555;
	overflow: auto;
}

@media screen and (max-width: 20px) {
	.navbarMimi a {
		float: none;
		display: block;
	}
}